import {
  getTotal,
} from "../../../../shared/functions/cartSummaryFunctions";

const { DateTime } = require("luxon");

const PROVIDERS = {
  ERP: "ERP",
  ECOMMERCE: "ECOMMERCE",
};

const AVAILABLE_PAYMENT_METHODS = {
  cash: "cash",
  pos: "pos",
  qr: "pos",
};

const TIMEZONES = {
  BO: "America/La_Paz", 
  PY: "America/Asuncion", 
};

const getDeliveryDate = (deliveryTimeInterval, countryIso) => {
  const timezone = TIMEZONES[countryIso] || "UTC"; 
  const now = DateTime.now().setZone(timezone);
  const end = now.plus({ minutes: 90 });

  const deliveryDate = `${end.toFormat("LLL dd")}, ${now.toFormat("hh:mm a")} - ${end.toFormat("hh:mm a")}`;
  return deliveryDate;
};

const parseOrderProducts = (products) => {
  return products.map((product) => {
    const price = product.getPrice();
    const discountPrice = product.getDiscountPrice();
    return {
      quantity: product.quantity,
      qtyPerUnit: product.qtyPerUnit || 1,
      isVariablePrice: false,
      markAsDelete: false,
      markAsUpdate: false,
      itemPrice: discountPrice ? discountPrice : price,
      label: product.label,
      category: product.category,
      subCategory: product.subCategory,
      sku: product.sku,
      description: product.description,
      imageUrl: product.imageUrl,
      unit: product.unit,
      uidProduct: product.id,
      marketProductItemId: product.id,
    };
  });
};

const getSummary = (products) => {
  const total = getTotal(products);
  return {
    cashCollection: total,
    total,
    subTotal: total,
    deductionFromWallet: 0,
  };
};

const getCustomerUid = (customer) => {
  if (!customer) return null;
  if (!customer.provider) return null;
  if (customer.provider === PROVIDERS.ERP) return null;
  if (customer.provider === PROVIDERS.ECOMMERCE) return customer.commerceUid;
  return null;
};

const parseToOrder = (pointOfSaleOrder, employee) => {
  const products = parseOrderProducts(pointOfSaleOrder.products);
  const summary = getSummary(pointOfSaleOrder.products);
  const customerUid = getCustomerUid(pointOfSaleOrder.customer);

  const paymentOptionUid = "cash";

  const { deliveryTimeInterval } = pointOfSaleOrder.store;
  const deliveryDate = getDeliveryDate(deliveryTimeInterval, pointOfSaleOrder.store.countryIso);

  const orderData = {
    whatsappOrder: pointOfSaleOrder.whatsappOrder,
    country: pointOfSaleOrder.store.countryIso,
    serviceUid: pointOfSaleOrder.store.serviceProviderUid,
    storeId: pointOfSaleOrder.store.id,
    serviceName: pointOfSaleOrder.store.name,
    currencySymbol: pointOfSaleOrder.store.countryIso === "BO" ? "Bs" : "Gs",
    cashCollection: summary.cashCollection,
    total: summary.total,
    subTotal: summary.subTotal,
    deductionFromWallet: summary.deductionFromWallet,
    minimumChargeFee: pointOfSaleOrder.store.minimumChargeFee,
    deliveryCharge: 0,
    hasPricing: pointOfSaleOrder.store.hasPricing,
    hasDelivery: pointOfSaleOrder.whatsappOrder ? true : false,
    items: products,
    paymentOptionUid,
    notes: `${pointOfSaleOrder.paymentMethod.toUpperCase()}:${employee.label}`,
    promiseTime: 1,
    deliveryDate,
    promoCode: null,
    discountType: null,
    discountValue: null,
    locale: "es",
    tips: 0,
  };
  if (customerUid) {
    orderData.commerceCustomerUid = customerUid;
  }
  if (pointOfSaleOrder.whatsappOrder) {
    orderData.customerName = pointOfSaleOrder.customer.name;
    orderData.customerPhoneNumber = pointOfSaleOrder.customer.phone;
    orderData.customerNit = pointOfSaleOrder.customer.nit || "";
    orderData.customerBillingName = pointOfSaleOrder.customer.socialReason || "";
    orderData.deliveryEddress = pointOfSaleOrder.deliveryEddress;
    orderData.deliveryLat = pointOfSaleOrder.deliveryLat;
    orderData.deliveryLon = pointOfSaleOrder.deliveryLon;
    orderData.erpId = pointOfSaleOrder.customer.erpId || "";
  }
  return orderData;
};

export default {
  parseToOrder,
};
